import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Protected from "./Router/Protected";
import Error from "./Components/Error";
const ContactUs = lazy(() => import("./Components/ContactUs"));
const Teachnologies = lazy(() => import("./Components/Technologies"));

function App() {
  localStorage.clear();
  return (
    <div className="parent_conatiner">
      <Suspense
        fallback={
          <div
            className="center"
            style={{
              fontSize: "5vw",
              fontWeight: "600",
            }}
          >
            Please Wait......
          </div>
        }
      >
        <Router>
          <Routes>
            <Route exact path="/" element={<Teachnologies />} />
            <Route
              exact
              path="/contactus"
              element={<Protected Component={ContactUs} />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
