import React from "react";
import "../CSS/style.css";

export default function Error() {
  return (
    <div className="center">
      <h1>
        404 Page Not Found
        <a style={{ color: "rgba(12, 130, 240)" }} href="/">
          Click_Here
        </a>
        TO GO Home Page
      </h1>
    </div>
  );
}
